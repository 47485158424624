<template>
  <div>
    <b-card title="Pannello di controllo AMY SUSHI 🍣">
      <b-card-text>Questo pannello di controllo è interamente customizzabile. E' sviluppato in VueJS e NodeJS. </b-card-text>
      <b-card-text>E' stato sviluppato in ottica di ampliamento, con la possibilità di aggiungere, modificare o rimuovere sezioni in base alle esigenze dell'azienda.
        <br>Hai delle richieste particolari? Contatta subito
        <b-link
          href="https://hyperialab.com/contact.html"
          target="_blank"
        >
          Hyperia Lab
        </b-link>.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
